export const userRoles = {
    "adminMaster" : process.env.REACT_APP_ENVIRONMENT == "PRO" ? 'SG_BA0613_01_P':'SG_BA0613_01_PP',
    "admin" : process.env.REACT_APP_ENVIRONMENT == "PRO" ? 'SG_BA0613_02_P' : 'SG_BA0613_02_PP' ,
    "ajAeropuertos" : process.env.REACT_APP_ENVIRONMENT == "PRO" ? 'SG_BA0613_03_P' : 'SG_BA0613_03_PP',
    "ajAeropuertosH" : process.env.REACT_APP_ENVIRONMENT == "PRO" ?'SG_BA0613_04_P' : 'SG_BA0613_04_PP',
    "ajCintra" : process.env.REACT_APP_ENVIRONMENT == "PRO" ?'SG_BA0613_05_P' : 'SG_BA0613_05_PP',
    "ajCintraH" : process.env.REACT_APP_ENVIRONMENT == "PRO" ?'SG_BA0613_06_P' : 'SG_BA0613_06_PP',
    "ajConstruccion" : process.env.REACT_APP_ENVIRONMENT == "PRO" ?'SG_BA0613_07_P' : 'SG_BA0613_07_PP',
    "ajConstruccionH" : process.env.REACT_APP_ENVIRONMENT == "PRO" ?'SG_BA0613_08_P' : 'SG_BA0613_08_PP',
    "ajCorporacion" : process.env.REACT_APP_ENVIRONMENT == "PRO" ?'SG_BA0613_09_P' : 'SG_BA0613_09_PP',
    "ajCorporacionH" : process.env.REACT_APP_ENVIRONMENT == "PRO" ?'SG_BA0613_10_P' : 'SG_BA0613_10_PP',
    "tesoAeropuertos" : process.env.REACT_APP_ENVIRONMENT == "PRO" ?'SG_BA0613_11_P' : 'SG_BA0613_11_PP',
    "tesoCintra" : process.env.REACT_APP_ENVIRONMENT == "PRO" ?'SG_BA0613_12_P' : 'SG_BA0613_12_PP',
    "tesoConstruccion" : process.env.REACT_APP_ENVIRONMENT == "PRO" ?'SG_BA0613_13_P' : 'SG_BA0613_13_PP',
    "tesoCorporacion" : process.env.REACT_APP_ENVIRONMENT == "PRO" ?'SG_BA0613_14_P' : 'SG_BA0613_14_PP',
    "ajFeim" : process.env.REACT_APP_ENVIRONMENT == "PRO" ?'SG_BA0613_15_P' : 'SG_BA0613_15_PP',
    "ajFeimH" : process.env.REACT_APP_ENVIRONMENT == "PRO" ?'SG_BA0613_16_P' : 'SG_BA0613_16_PP'
}

// export enum userRoles {
//     adminMaster = 'SG_BA0613_01_PP',
//     admin = 'SG_BA0613_02_PP',
//     ajAeropuertos = 'SG_BA0613_03_PP',
//     ajAeropuertosH = 'SG_BA0613_04_PP',
//     ajCintra = 'SG_BA0613_05_PP',
//     ajCintraH = 'SG_BA0613_06_PP',
//     ajConstruccion = 'SG_BA0613_07_PP',
//     ajConstruccionH = 'SG_BA0613_08_PP',
//     ajCorporacion = 'SG_BA0613_09_PP',
//     ajCorporacionH = 'SG_BA0613_10_PP',
//     tesoAeropuertos = 'SG_BA0613_11_PP',
//     tesoCintra = 'SG_BA0613_12_PP',
//     tesoConstruccion = 'SG_BA0613_13_PP',
//     tesoCorporacion = 'SG_BA0613_14_PP',
//     ajFeim = 'SG_BA0613_15_PP',
//     ajFeimH = 'SG_BA0613_16_PP'
// }