import { DocumentationApi } from '../../../api/documentation';
import { SendTraceToBBDD } from '../../../api/generic';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import useHistory from '../../../history';
import { DocumentDetail, Language, UserState } from '../../../model';
import { compareObjects, getResourcesFile, objectToString, showError, showSuccess } from '../../../utils/extensions';
const browserHistory = useHistory;

export const saveDocumentAction = (document: DocumentDetail, currentLanguage: Language, currentUser: UserState, documentationPrevio: DocumentDetail) => (dispatch) => {
	dispatch(httpCallStartAction());
	let language = getResourcesFile(currentLanguage);
	if (document.id > 0) {
		handleUpdateDocument(document, language, dispatch, currentUser, documentationPrevio);
	} else {
		handleCreateDocument(document, language, dispatch, currentUser);
	}
};

const handleUpdateDocument = (document: DocumentDetail, language: any, dispatch, currentUser: UserState, documentationPrevio: DocumentDetail) => {
	DocumentationApi.UpdateAsync(ApiUrls.Documentation, document).then((result: any) => {
		if (result.error) {
			showError(language['documentation']['messages']['error-update']);
			dispatch(saveDocumentCompleted(result));
		} else {
			showSuccess(language['documentation']['messages']['success-update']);
			dispatch(saveDocumentCompleted(result));
			const trazaDocumentation = {
				Usuario: currentUser.currentUser.email,
				Grupos: currentUser.currentUser.roles.join(','),
				Accion: "Update Document",
				Fecha: new Date().toDateString(),
				Traza: `Se ha modificado el documento ${document.id} ,con los siguientes cambios: ${objectToString(compareObjects(documentationPrevio, document))}`,
				IdRegistro: document.id.toString()
			}
			SendTraceToBBDD(trazaDocumentation)
			browserHistory.goBack();
		}
	});
};

const handleCreateDocument = (document: DocumentDetail, language: any, dispatch, currentUser: UserState) => {
	DocumentationApi.AddAsync(ApiUrls.Documentation, document).then((result: any) => {
		if (result > 0) {
			showSuccess(language['documentation']['messages']['success']);
			dispatch(saveDocumentCompleted(result));
			document.id = result;
			const trazaDocumentation = {
				Usuario: currentUser.currentUser.email,
				Grupos: currentUser.currentUser.roles.join(','),
				Accion: "Create Document",
				Fecha: new Date().toDateString(),
				Traza: `Se ha creado el documento ${document.id}`,
				IdRegistro: document.id.toString(),
				RegistroActual: objectToString(document),

			}
			SendTraceToBBDD(trazaDocumentation)
			browserHistory.goBack();
		} else {
			showError(language['documentation']['messages']['error']);
			dispatch(saveDocumentCompleted(result));
		}
	});
};

const saveDocumentCompleted = (result: number) => ({
	type: actionTypes.SAVE_DOCUMENTATION_COMPLETED,
	payload: result,
	meta: {
		httpEnd: true,
	},
});
